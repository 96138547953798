import {graphql, Link} from 'gatsby';
import Img, {FluidObject} from 'gatsby-image/withIEPolyfill';
import {css} from 'linaria';
import * as React from 'react';
import slugify from '../slugify';

function Product({
  product: {
    brand,
    name,
    type,
    description,
    price,
    link,
    store: [
      {
        data: {name: storeName},
      },
    ],
    image: {
      localFiles: [
        {
          childImageSharp: {fluid},
        },
      ],
    },
  },
}: {
  product: ProductData;
}) {
  const slug = slugify(type);
  return (
    <div
      className={css`
        margin-bottom: 10vh;
        @media (min-width: 480px) {
          display: inline-flex;
          width: 80vw;
          max-width: 800px;
          justify-content: center;
          align-items: center;
          margin-left: 5vw;
        }
      `}>
      <Img
        fluid={fluid}
        objectFit="contain"
        className={css`
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          @media (min-width: 480px) {
            flex: 2 0 0;
            height: 60vh;
          }
          @media (max-width: 479px) {
            height: 40vh;
            margin-bottom: 24px;
          }
        `}
      />
      <div
        className={css`
          @media (min-width: 480px) {
            flex: 1 0 0;
            width: 20%;
            margin-left: 5vw;
            text-align: left;
          }
        `}>
        <Link to={`/the-best-${slug}`} className="l mbxs">
          {type}
        </Link>
        <h3 className="b l mbs">
          {brand} {name}
        </h3>
        <div className="mbs">{description}</div>
        <div className="l mbs">${price}</div>
        <a href={link} target="_blank" className="ib button buy">
          Buy on {storeName}
        </a>
      </div>
    </div>
  );
}

export interface ProductData {
  rank: number;
  type: string;
  description: string;
  image: {
    localFiles: {
      childImageSharp: {
        fluid: FluidObject;
      };
    }[];
  };
  brand: string;
  name: string;
  price: number;
  store: {
    data: {
      name: string;
    };
  }[];
  category: {id: string}[];
  link: string;
}

export const productFragment = graphql`
  fragment ProductFragment on AirtableData {
    rank
    type
    description
    image {
      localFiles {
        childImageSharp {
          fluid(quality: 90, toFormat: JPG, background: "#ffffff") {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    brand
    name
    price
    store {
      data {
        name
      }
    }
    link
    category {
      id
    }
  }
`;

export default React.memo(Product);
